import { AppLayout, BreadcrumbGroupProps, Flashbar } from '@amzn/awsui-components-react';
import React from 'react';
import AdminConsoleSideNavigation from './AdminConsoleSideNavigation';
import WorkInProgress from '../../components/common/WorkInProgress';

export const AdminBaseBreadcrumbs: BreadcrumbGroupProps.Item[] = [
  {
    text: 'Admin console',
    href: '/admin-console/planning-cycle'
  }
];

const AdminConsole: React.FC<any> = () => {
  return (
    <>
      <AppLayout
        data-testid="admin-console-layout"
        headerSelector="#h"
        navigation={<AdminConsoleSideNavigation />}
        toolsHide={true}
        breadcrumbs={[]}
        notifications={<Flashbar items={[]} />}
        maxContentWidth={Number.MAX_VALUE}
        contentType="cards"
        content={
          <>
            <WorkInProgress />
          </>
        }
      />
    </>
  );
};

export default AdminConsole;
